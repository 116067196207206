import { atom } from "recoil"
import { UserLocation } from "./lib/get-user-location"
import { FilterList } from "./lib/filter-list"

export const filterListAtom = atom<FilterList>({
  key: "filterList",
  default: {},
})

export const userLocationAtom = atom<UserLocation | undefined>({
  key: "userLocation",
  default: undefined,
})
