import * as React from "react"
import { Store } from "../../../graphql-types"
import Container from "../../../components/container"
import StoreTile from "../store-tile"
import classNames from "classnames"

interface StoreInAreaProps {
  store: Store
  className?: string
}

export default function StoresInArea({ className, store }: StoreInAreaProps) {
  const stores = store.storesInArea.slice(0, 4)

  return (
    <div className={classNames(className, "bg-gray-100")}>
      <Container className={"items-center py-10 flex-col gap-8"}>
        <h3 className={"font-bold text-3xl"}>Others in the Area</h3>
        <Container breakpoint="md" className="items-start flex-wrap">
          {stores.map(storeInArea => (
            <div
              className={"sm:w-1/2 lg:w-1/4 px-2 py-2 md:py-0"}
              key={storeInArea.id}
            >
              <StoreTile store={storeInArea} />
            </div>
          ))}
        </Container>
      </Container>
    </div>
  )
}
