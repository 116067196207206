"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boundingBox = exports.earthRadiusByLat = exports.radiansToDegrees = exports.degreesToRadians = exports.distanceFromLocation = void 0;
function distanceFromLocation(locationOne, locationTwo) {
    const earthRadius = earthRadiusByLat(degreesToRadians(locationOne.lat)) / 1000.0;
    const dLat = degreesToRadians(locationTwo.lat - locationOne.lat);
    const dLon = degreesToRadians(locationTwo.lng - locationOne.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(locationOne.lat)) *
            Math.cos(degreesToRadians(locationTwo.lat)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    return earthRadius * c;
}
exports.distanceFromLocation = distanceFromLocation;
function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}
exports.degreesToRadians = degreesToRadians;
function radiansToDegrees(radians) {
    return radians * (180 / Math.PI);
}
exports.radiansToDegrees = radiansToDegrees;
const WGS84_a = 6378137.0; // Major semiaxis [m]
const WGS84_b = 6356752.3; // Minor semiaxis [m]
function earthRadiusByLat(latitude) {
    // http://en.wikipedia.org/wiki/Earth_radius
    const An = WGS84_a * WGS84_a * Math.cos(latitude);
    const Bn = WGS84_b * WGS84_b * Math.sin(latitude);
    const Ad = WGS84_a * Math.cos(latitude);
    const Bd = WGS84_b * Math.sin(latitude);
    return Math.sqrt((An * An + Bn * Bn) / (Ad * Ad + Bd * Bd));
}
exports.earthRadiusByLat = earthRadiusByLat;
function boundingBox(latitudeInDegrees, longitudeInDegrees, halfSideInKm) {
    const lat = degreesToRadians(latitudeInDegrees);
    const lon = degreesToRadians(longitudeInDegrees);
    const halfSide = 1000 * halfSideInKm;
    const earthRadius = earthRadiusByLat(lat);
    const pradius = earthRadius * Math.cos(lat);
    const latMin = lat - halfSide / earthRadius;
    const latMax = lat + halfSide / earthRadius;
    const lonMin = lon - halfSide / pradius;
    const lonMax = lon + halfSide / pradius;
    return {
        latMin: radiansToDegrees(latMin),
        lngMin: radiansToDegrees(lonMin),
        latMax: radiansToDegrees(latMax),
        lngMax: radiansToDegrees(lonMax),
    };
}
exports.boundingBox = boundingBox;
