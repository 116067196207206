import * as React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

interface TabletLinkProps {
  title: string
  link: string
  className?: string
}

export default function TabletLink({
  className,
  title,
  link,
}: TabletLinkProps) {
  return (
    <Link
      to={link}
      className={classNames(
        "bg-gray-200 text-xs px-2 py-1 text-gray-600 rounded-md hover:bg-black hover:text-white transition-colors",
        className
      )}
    >
      {title}
    </Link>
  )
}
