import * as React from "react"
import { Store } from "../../../graphql-types"
import Gallery, {
  GalleryImage,
  GalleryWrapper,
} from "../../../components/gallery"
import { getSrc, ImageDataLike } from "gatsby-plugin-image"
import Container from "../../../components/container"
import classNames from "classnames"

interface StoreGalleryProps {
  store: Store
  className?: string
}

export default function StoreGallery({ className, store }: StoreGalleryProps) {
  let galleryImages: GalleryImage[] = []

  if (store.gallery && store.gallery.length > 0) {
    galleryImages = store.gallery.map(
      galleryImage =>
        ({
          thumbnail: galleryImage.image,
          galleryImage:
            getSrc(galleryImage.image as unknown as ImageDataLike) ?? "",
          alt: store.title,
        } as GalleryImage)
    )
  } else if (store.googlePhotos && store.googlePhotos.length > 0) {
    galleryImages = store.googlePhotos.map(
      googlePhoto =>
        ({
          thumbnail: googlePhoto.localFile,
          galleryImage: getSrc(
            googlePhoto.localFile as unknown as ImageDataLike
          ),
          alt: store.title,
        } as GalleryImage)
    )
  }

  return galleryImages.length > 0 ? (
    <GalleryWrapper>
      <Container className={classNames(className, "mb-8")}>
        <Gallery images={galleryImages} />
      </Container>
    </GalleryWrapper>
  ) : null
}
