import * as React from "react"
import { Store } from "../../../graphql-types"
import ButtonLink from "../../../components/button-link"
import { appleMapsUrl, googleMapsUrl } from "../../../lib/url-helpers"
import { FaGoogle, FaApple } from "react-icons/fa"
import Hero from "../../../components/hero"

interface StoreHeroProps {
  store: Store
}

export default function StoreHero({ store }: StoreHeroProps) {
  const featureImageToUse = store.heroImage ?? store.chain.heroImage

  return (
    <Hero
      title={store.title}
      subtitle={store.address}
      heroImage={featureImageToUse}
    >
      <div className={"flex gap-4 justify-center flex-wrap"}>
        <ButtonLink
          theme={"light-outline"}
          hoverTheme={"dark"}
          href={googleMapsUrl(store)}
          target={"_blank"}
          className={"flex items-center gap-2"}
        >
          <FaGoogle />
          Directions
        </ButtonLink>
        <ButtonLink
          theme={"light-outline"}
          hoverTheme={"dark"}
          href={appleMapsUrl(store)}
          target={"_blank"}
          className={"flex items-center gap-2"}
        >
          <FaApple />
          Directions
        </ButtonLink>
      </div>
    </Hero>
  )
}
