import * as React from "react"
import { FaDollarSign } from "react-icons/fa"
import classNames from "classnames"

interface PriceRatingIconProps {
  type?: "full"
  size: number
  color?: string
}

const PriceRatingIcon = ({
  type = "full",
  size,
  color,
}: PriceRatingIconProps) => {
  const types = {
    half: FaDollarSign,
    full: FaDollarSign,
    empty: FaDollarSign,
  }

  const MyRating = types[type]

  return <MyRating size={size} color={color} />
}

const RatingFull = ({
  size,
  color,
}: Pick<PriceRatingIconProps, "size" | "color">) => (
  <PriceRatingIcon size={size} color={color} />
)

interface PriceRatingProps {
  darkMode?: boolean
  className?: string
  rating: number
  size?: number
}

export default function PriceRating({
  darkMode,
  className,
  rating,
  size = 20,
}: PriceRatingProps) {
  const color = darkMode ? "white" : "black"
  const offColor = "gray"

  const ratings = () => {
    const ratingList = []

    for (let i = 0; i < Math.floor(rating); i++) {
      ratingList.push(
        <RatingFull key={`rating-${i}`} size={size} color={color} />
      )
    }

    for (let i = Math.ceil(rating); i < 3; i++) {
      ratingList.push(
        <RatingFull key={`rating-empty-${i}`} size={size} color={offColor} />
      )
    }

    return ratingList
  }

  return <div className={classNames("flex", className)}>{ratings()}</div>
}
