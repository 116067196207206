import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { Store, StorePageQuery } from "../../graphql-types"
import Layout from "../../components/layout"
import StoreHero from "../../partials/store/store-hero"
import StoreStrip from "../../partials/store/store-strip"
import StoreContent from "../../partials/store/store-content"
import StoreGallery from "../../partials/store/store-gallery"
import StoreReviews from "../../partials/store/store-reviews"
import StoresInArea from "../../partials/store/stores-in-area"

export default function StorePage({ data }: PageProps<StorePageQuery>) {
  const { store } = data

  return (
    <Layout noGap className={"bg-white"}>
      <StoreHero store={store as Store} />
      <StoreStrip store={store as Store} />
      <StoreContent store={store as Store} />
      <StoreGallery store={store as Store} />
      <StoreReviews store={store as Store} />
      <StoresInArea store={store as Store} />
    </Layout>
  )
}

export const query = graphql`
  query StorePage($id: String!) {
    store(id: { eq: $id }) {
      ...StoreCommon
      ...StoreVarieties
      ...StoreTileImages
      ...StoreLocation

      heroImage {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 3.3157894737
            width: 1500
            height: 450
            transformOptions: { fit: COVER }
          )
        }
      }

      googlePhotos {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      gallery {
        alt
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }

      reviews {
        authorName
        authorUrl
        profilePhotoUrl
        rating
        text
        time
        timeDescription
      }

      featureImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }

      chain {
        title
        slug
        body
        pricing
        heroImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              aspectRatio: 3.3157894737
              transformOptions: { fit: COVER }
            )
          }
        }
      }

      storesInArea {
        ...StoreCommon
        ...StoreVarieties
        ...StoreChain
        ...StoreTileImages
        ...StoreLocation
      }
    }
  }
`
