import * as React from "react"
import Container from "../../../components/container"
import { Store } from "../../../graphql-types"
import { PropsWithChildren } from "react"
import Rating from "../../../components/rating"
import PriceRating from "../../../components/price-rating"
import TabletLink from "../../../components/tablet-link"
import * as urlHelpers from "../../../lib/url-helpers"
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa"
import TabletButton from "../../../components/tablet-button"
import StoreTradingHoursPopup from "../store-trading-hours-popup"

interface StoreStripProps {
  store: Store
}

interface StoreStripTileProps {
  title: string
}

function StoreStripTile({
  title,
  children,
}: PropsWithChildren<StoreStripTileProps>) {
  return (
    <div
      className={
        "flex md:flex-col mb-6 md:mb-0 gap-4 justify-between items-center"
      }
    >
      <h3 className={"text-gray-800 uppercase font-bold"}>{title}</h3>
      <div className={"flex gap-2"}>{children}</div>
    </div>
  )
}

export default function StoreStrip({ store }: StoreStripProps) {
  const [showTradingHours, setShowTradingHours] = React.useState(false)

  return (
    <Container fullWidth className={"bg-gray-100"}>
      <Container
        className={"justify-between flex-col md:flex-row py-6 md:py-12"}
      >
        <StoreStripTile title={"Rating"}>
          <Rating rating={store.googleRating} />
        </StoreStripTile>
        <StoreStripTile title={"Pricing"}>
          <PriceRating
            size={15}
            rating={store.pricing || store.chain.pricing}
          />
        </StoreStripTile>
        <StoreStripTile title={"Variety"}>
          {store.varieties.map(variety => {
            return (
              <TabletLink
                key={`${store.id}-${variety?.slug}`}
                title={variety.title}
                link={urlHelpers.varietyUrl(variety)}
              />
            )
          })}
        </StoreStripTile>
        <StoreStripTile title={"Central?"}>
          {store.distanceFromCityCentre <= 5 ? (
            <FaRegCheckCircle size={20} />
          ) : (
            <FaRegTimesCircle />
          )}
        </StoreStripTile>
        <StoreStripTile title={"Trading Hours"}>
          <TabletButton
            title={"Show"}
            onClick={() => setShowTradingHours(true)}
          />
        </StoreStripTile>
      </Container>
      <StoreTradingHoursPopup
        store={store}
        onClose={() => setShowTradingHours(false)}
        isOpen={showTradingHours}
      />
    </Container>
  )
}
