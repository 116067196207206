import * as React from "react"
import classnames from "classnames"
import {
  googleAdsensePublisherId,
  enableAdTesting,
  enableAds,
} from "../../config"

interface BannerProps {
  className?: string
  style?: React.CSSProperties
  layout?: string
  format?: string
  client?: string
  responsive?: boolean
  slot: string
  layoutKey?: string
}

export default function Banner({
  className,
  style,
  layout,
  format,
  client = `ca-pub-${googleAdsensePublisherId}`,
  slot,
  responsive,
  layoutKey,
}: BannerProps) {
  if (!enableAds && !enableAdTesting) {
    return <></>
  }

  React.useEffect(() => {
    const adsbygoogle = window.adsbygoogle ?? []
    adsbygoogle.push({})
  }, [])

  return (
    <ins
      className={classnames("block", "adsbygoogle", className, {
        ["border-2"]: enableAdTesting,
        ["border-red-600"]: enableAdTesting,
      })}
      style={style}
      data-ad-layout={layout}
      data-ad-format={format}
      data-ad-client={client}
      data-adtest={enableAdTesting ? "on" : undefined}
      data-ad-slot={slot}
      data-ad-layout-key={layoutKey}
      data-full-width-responsive={responsive}
    />
  )
}
