import * as React from "react"
import { MdStar, MdStarOutline, MdOutlineStarHalf } from "react-icons/md"
import classNames from "classnames"

interface RatingIconProps {
  type?: "half" | "full" | "empty"
  size: number
  color?: string
}

const RatingIcon = ({ type = "full", size, color }: RatingIconProps) => {
  const types = {
    half: MdOutlineStarHalf,
    full: MdStar,
    empty: MdStarOutline,
  }

  const MyRating = types[type]

  return <MyRating size={size} color={color} />
}

const RatingFull = ({
  size,
  color,
}: Pick<RatingIconProps, "size" | "color">) => (
  <RatingIcon size={size} color={color} />
)
const RatingHalf = ({
  size,
  color,
}: Pick<RatingIconProps, "size" | "color">) => (
  <RatingIcon type="half" size={size} color={color} />
)

const RatingEmpty = ({
  size,
  color,
}: Pick<RatingIconProps, "size" | "color">) => (
  <RatingIcon type="empty" size={size} color={color} />
)

interface RatingProps {
  darkMode?: boolean
  className?: string
  rating: number
  size?: number
}

export default function Rating({
  darkMode,
  className,
  rating,
  size = 20,
}: RatingProps) {
  const showHalf = rating - Math.floor(rating) !== 0
  const color = darkMode ? "white" : "black"

  const ratings = () => {
    const ratingList = []

    for (let i = 0; i < Math.floor(rating); i++) {
      ratingList.push(
        <RatingFull key={`rating-${i}`} size={size} color={color} />
      )
    }

    if (showHalf) {
      ratingList.push(
        <RatingHalf key="rating-half" size={size} color={color} />
      )
    }

    for (let i = Math.ceil(rating); i < 5; i++) {
      ratingList.push(
        <RatingEmpty key={`rating-empty-${i}`} size={size} color={color} />
      )
    }

    return ratingList
  }

  return <div className={classNames("flex", className)}>{ratings()}</div>
}
