import * as React from "react"
import {Store} from "../../../graphql-types"
import Container from "../../../components/container"
import Rating from "../../../components/rating"
import Content from "../../../components/content"
import Heading from "../../../components/heading"
import classNames from "classnames"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

interface StoreReviewsProps {
  store: Store
}

export default function StoreReviews({store}: StoreReviewsProps) {
  return (
    <Container className={"max-w-2xl flex-col justify-center mb-10"}>
      <div className={"text-center mb-6"}>
        <Heading>Reviews</Heading>
        <p className={"text-xs"}>
          The following reviews have been sourced from Google.
        </p>
      </div>
      <div className={"flex flex-col border shadow"}>
        {store.reviews
          ?.sort((a, b) => parseInt(b.time) - parseInt(a.time))
          ?.map((review, index) => {
            return (
              <div
                className={classNames("p-4 flex flex-col gap-4", {
                  "border-b-2": store.reviews.length !== index - 1,
                })}
                key={`review-${index}`}
              >
                <div className={"flex items-center gap-4"}>
                  <a href={review.authorUrl} target={"_blank"} rel="noreferrer">
                    <img
                      width={35}
                      height={35}
                      alt={review.authorName}
                      src={
                        review.profilePhotoUrl ?? "https://placehold.it/35x35"
                      }
                    />
                  </a>

                  <div>
                    <span>{review.authorName}</span>
                    <Rating size={15} rating={review.rating}/>
                  </div>
                </div>

                {review.text && <Content size={"small"}>{review.text}</Content>}

                <time
                  className={"text-xs text-gray-400"}
                  dateTime={review.time}
                >
                  {dayjs().to(dayjs.unix(parseInt(review.time)))}
                </time>
              </div>
            )
          })}
      </div>
    </Container>
  )
}
