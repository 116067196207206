import * as React from "react"
import Banner from "../../../components/banner"

interface StoreAdProps {
  slot: string
}

export default function StoreAd({ slot }: StoreAdProps) {
  return <Banner slot={slot} format={"fluid"} layout={"in-article"} />
}
