import * as React from "react"
import * as urlHelpers from "../../../lib/url-helpers"
import Rating from "../../../components/rating"
import { Link } from "gatsby"
import PriceRating from "../../../components/price-rating"
import { Store } from "../../../graphql-types"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import TabletLink from "../../../components/tablet-link"
import classNames from "classnames"
import { useRecoilValue } from "recoil"
import { userLocationAtom } from "../../../atoms"
import { distanceFromLocation } from "gatsby-source-tokyo-chuko/src/utils/geo"
import { UserLocation } from "../../../lib/get-user-location"

interface StoreTileProps {
  store: Store
  className?: string
}

function getDistance(store: Store, userLocation: UserLocation | undefined) {
  if (store.distance) return store.distance
  if (!userLocation) return undefined

  return distanceFromLocation(userLocation, {
    lat: store.latitude,
    lng: store.longitude,
  })
}

export default function StoreTile({ store, className }: StoreTileProps) {
  const userLocation = useRecoilValue(userLocationAtom)
  const distance = getDistance(store, userLocation)

  const featureImage = getImage(
    (store.featureImage ?? store.chain?.postImage) as unknown as ImageDataLike
  )

  return (
    <div
      className={classNames(
        "flex w-full md:w-auto md:flex-col p-2 md:p-0 shadow-gray-300 shadow-sm bg-white hover:shadow-md transition-all",
        className
      )}
    >
      <div className={"relative flex items-center w-1/4 md:w-auto"}>
        <Link to={urlHelpers.storeUrl(store)}>
          {featureImage && (
            <GatsbyImage
              loading={"lazy"}
              alt={`${store.title} - ${store.location?.title}`}
              image={featureImage}
            />
          )}
        </Link>
        <div className={"absolute top-6 left-6 hidden md:block"}>
          <Rating darkMode={true} rating={store.googleRating ?? 0} />
        </div>
      </div>

      {distance !== undefined && (
        <div
          className={"flex hidden md:block bg-gray-800 py-2 px-4 items-center"}
        >
          <span className={"inline-block rounded text-xs font-bold text-white"}>
            {distance < 1 ? "< 1" : Math.ceil(distance).toLocaleString()} km
            away
          </span>
        </div>
      )}

      <div className={"bg-white flex-grow pl-4 md:p-4"}>
        <div className={"flex items-center justify-between"}>
          <Link
            to={urlHelpers.storeUrl(store)}
            className={"block text-lg font-extrabold hover:underline"}
          >
            {store.title}
          </Link>
          <div className={"block md:hidden"}>
            <Rating
              darkMode={false}
              size={18}
              rating={store.googleRating ?? 0}
            />
          </div>
        </div>
        <div className={"flex items-center mb-2 justify-between"}>
          <Link
            to={urlHelpers.locationUrl(store.location)}
            className={
              "block italic text-neutral-700 font-light hover:underline"
            }
          >
            {store.location.title}
          </Link>

          <div className={"block md:hidden"}>
            <PriceRating
              darkMode={false}
              size={13}
              rating={store.pricing ?? store.chain?.pricing ?? 0}
            />
          </div>
        </div>

        <div className={"flex justify-between items-center"}>
          <div className={"flex gap-1"}>
            {store.varieties?.map(variety => (
              <TabletLink
                key={`${store.id}-${variety?.slug}`}
                title={variety?.title}
                link={urlHelpers.varietyUrl(variety)}
              />
            ))}
          </div>
          {distance !== undefined && (
            <div
              className={"flex md:hidden bg-gray-800 py-1 px-2 items-center"}
            >
              <span
                className={"inline-block rounded text-xs font-bold text-white"}
              >
                {distance < 1 ? "< 1" : Math.ceil(distance).toLocaleString()} km
                away
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
