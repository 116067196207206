import * as React from "react"
import { Store } from "../../../graphql-types"
import Container from "../../../components/container"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import GoogleMap from "../../../components/google-map"
import Heading from "../../../components/heading"
import Content from "../../../components/content"
import ButtonGroup from "../../../components/button-group"
import StoreAd from "../store-ad"

interface StoreContentProps {
  store: Store
}

export default function StoreContent({ store }: StoreContentProps) {
  return (
    <Container className={"py-12 max-w-3xl flex-col gap-8"}>
      {store.chain?.body && (
        <div className={"flex flex-col gap-4"}>
          <Heading>About {store.chain.title}</Heading>
          <Content html={store.chain.body} />
        </div>
      )}

      <StoreAd slot={"6202665780"} />

      {store.description && (
        <div className={"flex flex-col gap-4"}>
          <Heading>Store Information</Heading>
          <Content html={store.description ?? store.chain.body} />
        </div>
      )}

      <div>
        <ButtonGroup
          links={[
            {
              href: store.websiteUrl,
              content: "View Website",
              visible: !!store.websiteUrl,
            },
            {
              href: store.facebookUrl,
              content: <FaFacebookF size={20} />,
              visible: !!store.facebookUrl,
            },
            {
              href: store.instagramUrl,
              content: <FaInstagram size={20} />,
              visible: !!store.instagramUrl,
            },
            {
              href: store.twitterUrl,
              content: <FaTwitter size={20} />,
              visible: !!store.twitterUrl,
            },
          ]}
        />
      </div>

      <div>
        <GoogleMap
          placeId={store.placeId}
          title={store.title}
          className={"w-full"}
        />
      </div>

      <StoreAd slot={"6706697279"} />
    </Container>
  )
}
