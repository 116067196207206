import * as React from "react"
import { MouseEventHandler } from "react"
import classNames from "classnames"

interface TabletButtonProps {
  title: string
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
}

export default function TabletButtonLink({
  className,
  title,
  onClick,
}: TabletButtonProps) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "bg-gray-200 text-xs px-2 py-1 text-gray-600 rounded-md hover:bg-black hover:text-white transition-colors",
        className
      )}
    >
      {title}
    </button>
  )
}
