import * as React from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Masonry from "react-masonry-css"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { File } from "../../graphql-types"
import classNames from "classnames"

export function GalleryWrapper({ children }: React.PropsWithChildren<any>) {
  return <SimpleReactLightbox>{children}</SimpleReactLightbox>
}

export interface GalleryImage {
  galleryImage: string
  thumbnail: File | string
  alt: string
}

interface GalleryProps {
  className?: string
  images: GalleryImage[]
}

export default function Gallery({ images, className }: GalleryProps) {
  const masonryBreakpointColumns = {
    default: 3,
    1300: 2,
    500: 1,
  }

  return (
    <SRLWrapper>
      <div className={"w-full flex justify-center"}>
        <Masonry
          breakpointCols={masonryBreakpointColumns}
          className={classNames(
            "flex justify-center flex-wrap w-auto",
            className
          )}
          columnClassName={"bg-clip-padding"}
        >
          {images.map(({ alt, thumbnail, galleryImage }, index) => (
            <a
              key={`gallery-img-${index}`}
              className={"block bg-primary text-center p-2"}
              href={galleryImage}
            >
              {typeof thumbnail === "string" ? (
                <img src={thumbnail} alt={alt} className={"w-full"} />
              ) : (
                <GatsbyImage
                  alt={alt}
                  loading={"lazy"}
                  className={"w-full"}
                  image={getImage(thumbnail as unknown as IGatsbyImageData)!}
                />
              )}
            </a>
          ))}
        </Masonry>
      </div>
    </SRLWrapper>
  )
}
