import * as React from "react"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"
import classNames from "classnames"

export interface ModalProps {
  trigger?: React.ReactElement
  cookieName?: string
  isOpen?: boolean
  onClose?: () => void
  className?: string
}

export default function Modal({
  children,
  isOpen = false,
  onClose,
  trigger,
  className,
}: React.PropsWithChildren<ModalProps>) {
  return (
    <Popup
      closeOnDocumentClick
      className={classNames("animate-on-open modal", className)}
      open={isOpen}
      trigger={trigger}
      onClose={onClose}
      position="center center"
    >
      <div>
        <div className="text-right px-2 md:px-5">
          <button
            title={"Close Modal"}
            onClick={onClose}
            className="text-4xl transition-colors focus:outline-none hover:text-primary-dark"
          >
            &times;
          </button>
        </div>
        <div className={"px-2 pb-2 md:px-5 md:pb-5"}>{children}</div>
      </div>
    </Popup>
  )
}
