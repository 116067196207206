import * as React from "react"
import Modal, { ModalProps } from "../../../components/modal"
import { Store } from "../../../graphql-types"

interface StoreTradingHoursPopupProps extends ModalProps {
  store: Store
}

export default function StoreTradingHoursPopup({
  store,
  isOpen,
  onClose,
}: StoreTradingHoursPopupProps) {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      className={"store-trading-hours-popup"}
    >
      <div className={"text-center"}>
        <h1 className={"pb-5 text-3xl uppercase font-bold text-primary"}>
          {store.title} Trading Hours
        </h1>
        <ul>
          {store.tradingHours?.map((day, i) => (
            <li key={`trading-hour-${i}`}>{day}</li>
          ))}
        </ul>
      </div>
    </Modal>
  )
}
